import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { SEO } from "../../components/seo"

const Page = () => {
  const data = useStaticQuery(graphql`
    query {
      kritor: file(relativePath: { eq: "content/kritor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="Intresseanmälan" />
      <Layout>
        <div className="relative bg-white">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={data.kritor.childImageSharp.fluid}
              />
            </div>
          </div>
          <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
            <div className="lg:pr-8">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                  Vad är viktigast för dig då du väljer förskola?
                </h2>
                <p className="mt-4 text-lg leading-7 text-warm-grey-500 sm:mt-3">
                  Ute mycket? All mat lagad på plats? Små barngrupper? Liten,
                  erfaren personalgrupp eller kanske möjlighet att följa barnen
                  i vardagen? Alla dessa saker är viktiga för oss och kanske
                  även för dig?
                </p>
                <p className="mt-4 text-lg leading-7 text-warm-grey-500 sm:mt-3">
                  Vill ni anmäla er till förskolan,{" "}
                  <a
                    href="https://www.ornskoldsvik.se/utbildning-och-barnomsorg/forskolebarn/forskola/om-plats-i-forskola"
                    target="_blank"
                    className="underline font-bold"
                    rel="noopener noreferrer"
                  >
                    klicka här
                  </a>
                  .
                </p>
                <p className="mt-4 text-lg leading-7 text-warm-grey-500 sm:mt-3">
                  Vill ni veta mer om förskolan, kontakta rektorn via{" "}
                  <a
                    href="mailto:forskolechef@forskolanbrandbilen.se"
                    className="underline font-bold"
                    rel="noopener noreferrer"
                  >
                    forskolechef@forskolanbrandbilen.se
                  </a>{" "}
                  eller styrelsen via{" "}
                  <a
                    href="mailto:styrelsen@forskolanbrandbilen.se"
                    className="underline font-bold"
                    rel="noopener noreferrer"
                  >
                    styrelsen@forskolanbrandbilen.se
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page
